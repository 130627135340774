<template>
  <div>
    <AssessmentContainer
      is-example
      :exam="exam"
      :example-questions="questions"
    />
  </div>
</template>

<script>
import AssessmentContainer from '@components/Assessments/AssessmentContainer'

import { mapGetters } from 'vuex'

export default {
  components: {
    AssessmentContainer
  },

  page() {
    if (!this.exam) {
      return
    }
    return {
      title: this.pageMeta.title,
      meta: [
        { name: 'description', content: this.pageMeta.description },
        { rel: 'canonical', href: `${process.env.VUE_APP_SPLASH_URL}${this.$route.fullPath}` },

        { property: 'og:title', content: this.pageMeta.title },
        { property: 'og:site_name', content: process.env.VUE_APP_SATELLITE_NAME },
        { property: 'og:url', content: `${process.env.VUE_APP_SPLASH_URL}${this.$route.fullPath}` },
        { property: 'og:image', content: `${process.env.VUE_APP_SPLASH_URL}/img/tc-logo-white.png` },
        { property: 'og:type', content: 'website' },
        { property: 'og:description', content: this.pageMeta.description },

        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: this.pageMeta.title },
        { name: 'twitter:description', content: this.pageMeta.description }
      ]
    }
  },

  data() {
    return {
      exam: null,
      questions: null
    }
  },

  computed: {
    ...mapGetters({
      haveExamsLoaded: 'exams/haveExamsLoaded',
      examBySlug: 'exams/examBySlug'
    }),

    pageMeta() {
      const titles = {
        testcandidates: `Pre-Employment Assessments: ${this.exam.name} Example`,
        picked: `Recruitment Assessment Tools: Free ${this.exam.name} Example`
      }
      const descriptions = {
        testcandidates: `Introduce pre-employment assessments into your recruitment process. Try our ${this.exam.name} test example to shortlist candidates and hire top talent.`,
        picked: `Introduce recruitment assessment tools into your hiring process. Try our ${this.exam.name} example test to shortlist candidates and find the best talent.`
      }
      return {
        title: titles[process.env.VUE_APP_SATELLITE],
        description: descriptions[process.env.VUE_APP_SATELLITE]
      }
    },

    /**
     * @return {String}
     */
    examSlug() {
      return this.$route.params.assessment
    }
  },

  created() {
    if (this.examSlug !== 'drives' && this.examSlug !== 'personality') {
      this.exam = require(`@data/exampleTests/${this.examSlug}/exam.json`)
      this.questions = require(`@data/exampleTests/${this.examSlug}/questions.json`)
    } else {
      this.exam = {
        slug: this.examSlug,
        instruction_slug: this.examSlug,
        personalityTraits: []
      }
      this.questions = []
    }
  }
}
</script>
